import { Component, OnInit } from '@angular/core';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  public selectedIndex = 0;
  public slideInterval = 6000;
  constructor() { }

  ngOnInit() {
    // this.autoSlide();
    // this.showSlides();
  }

  slides = [
    { 'image': './assets/images/pre-login/RC-banner_wastage-calc_190606_v03.jpg' },
    { 'image': './assets/images/pre-login/wp_prov_transparency_bg_04.jpg' },
    { 'image': './assets/images/pre-login/180718_free-trial-slider-rc-hompage_v02.jpg' },
    { 'image': './assets/images/pre-login/RC-2_Banner-Image-Options_170602_v06.jpg' }
  ];
  /*  slides = [
     { 'image': '.\banner1' },
     { 'image': '.\banner2' },
     { 'image': '.\banner1' },
     { 'image': '.\banner2' }
   ]; */

  public autoSlide(): void {
    setInterval(() => {
      this.onNextClick();
    }, this.slideInterval);
  }

  public selectImage(index: number): void {
    this.selectedIndex = index;
  }

  public onPrevClick(): void {
    if (this.selectedIndex === 0) {
      this.selectedIndex = this.slides.length - 1;
    } else {
      this.selectedIndex--;
    }
  }

  public onNextClick(): void {
    if (this.selectedIndex === this.slides.length - 1) {
      this.selectedIndex = 0;
    } else {
      this.selectedIndex++;
    }
  }
  
}
