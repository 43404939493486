import { Injectable, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AccountOutput } from '../models/account-output.data.model';
import { CheckSessionOutput } from '../models/checksession-output.data.model';
import { EnvironmentData } from '../models/environment.data.model';

@Injectable({
    providedIn: 'root',
  })

export class AuthorizationService {
  public headers: HttpHeaders = null;
  public config:EnvironmentData;
  constructor(
    private _http: HttpClient,
    )
  {}
  private setHeaders(): HttpHeaders {
    this.config = JSON.parse(sessionStorage.getItem('config'));
    let apiKey = sessionStorage.getItem('ak')
    return this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', apiKey)
      .set('Authorization', sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') : this.config.Authorization);
  }
  public getConfig():Observable<EnvironmentData>{
    return this._http.get('./assets/config/config.json') as Observable<EnvironmentData>;
  }
  public getAmaLoginDetails(jsonData:object) : Observable<AccountOutput>{
    let headers =  this.setHeaders();
    return this._http.post(this.config.ServiceUrl + '/amalogin',	jsonData,{headers});
  }
  public getAccountDetails(accountId:string) : Observable<AccountOutput>{
    let headers =  this.setHeaders();
    return this._http.post(this.config.ServiceUrl + '/account', { 'accountid': accountId }, {headers});
  }
  public decryptMessage(jsonData:object) : Observable<string>{
    this.setHeaders();
    return this._http.post(this.config.CryptoWebService + 'demessage',  jsonData,{responseType: 'text'} );
  }
  public deleteSession(sessionId:string) : Observable<AccountOutput>{
    let headers =  this.setHeaders();
    return this._http.post(this.config.ServiceUrl + '/deleteSession',	{	'session_id': sessionId},{headers});
  }
   public checkSession(sessionId:string) : Observable<CheckSessionOutput>{
    let headers =  this.setHeaders();
    return this._http.post(this.config.ServiceUrl + '/checkSession',	{	'session_id': sessionId},{headers});
  }
  public decrypt(encodedData: string) : Observable<string> {
    this.setHeaders();
    return this._http.post(this.config.CryptoWebService + "demessage" ,	{	'key': encodedData},{responseType: 'text'});
  }
}
