import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-unauth-blog',
  templateUrl: './unauth-blog.component.html',
  styleUrls: ['./unauth-blog.component.scss']
})
export class UnauthBlogComponent implements OnInit {
  public showResults: boolean = false;
  BlogForm = this.fb.group({
    blogText: [null, Validators.required]
  })

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    if(this.BlogForm.controls.blogText.value){
      this.showResults = true
    } else  { 
      this.showResults = false
    }
  }
}
