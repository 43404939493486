import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[alphabetAndSpaceOnly]',
})

export class AlphabetOnlyDirective {
    public key: number;
    constructor(private _el: ElementRef) { }
    @HostListener('keydown', ['$event']) public onKeydown(event: KeyboardEvent): void {
        const initalValue = this._el.nativeElement.value;
        this.key = event.keyCode;
        if (this.key === 32 && !initalValue.length) {
            event.preventDefault();
        } else {
        if ((this.key >= 15 && this.key <= 31) || (this.key >= 47 && this.key <= 64) || (this.key >= 123) || (this.key >= 96 && this.key <= 111)) {
                event.preventDefault();
            }
        }
    }
}
