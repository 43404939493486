import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner3',
  templateUrl: './banner3.component.html',
  styleUrls: ['./banner3.component.scss']
})
export class Banner3Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
