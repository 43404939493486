import {
    Directive,
    ElementRef,
    HostListener
} from '@angular/core';

@Directive({
  selector: '[onlyAlphaNumeric]',
})
export class OnlyAlphaNumericDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event'])  public onInputChange(event:MouseEvent|FocusEvent|TouchEvent|DragEvent|KeyboardEvent):void {
    const initalValue = this._el.nativeElement.value;

    this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9.]/gi, '');
    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

}
