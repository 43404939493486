import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {

  constructor(public router: Router) {
  }

  // tslint:disable-next-line:no-any
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
            console.error('Error Event');
          } else {
            switch (error.status) {
              case 403:     //session expired
                alert('Your session has expired. Please log in again');
                sessionStorage.removeItem('isValidUser');
                sessionStorage.removeItem('response');
                sessionStorage.removeItem('currentUser');
                sessionStorage.removeItem('encKey');
                this.router.navigate(['/logout']);
                // location.replace(JSON.parse(sessionStorage['config']).LogOutPage);
                sessionStorage.removeItem('config');
                break;
              //More common errors can be added here
            }
          }
        } else {
          console.error('some thing else happened');
        }
        return throwError(error);
      })
    );
  }
}
