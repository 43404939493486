import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountOutput } from '../models/account-output.data.model';
import { EnvironmentData } from '../models/environment.data.model';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})

export class LoginService {
  public headers: HttpHeaders = null;
  public config: EnvironmentData = JSON.parse(sessionStorage.getItem('config'));
  public sessionId: string = '';
  public browserData = this.commonService.getBrowserDetails();

  constructor(
    private _http: HttpClient,
    private commonService: CommonService,
    private router: Router,
    public snackbar: MatSnackBar
  ) { }

  private setHeaders(): HttpHeaders {
    this.config = JSON.parse(sessionStorage.getItem('config'));
    let apiKey = sessionStorage.getItem('ak')
    return this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', apiKey)
      .set('Authorization', sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') : this.config.Authorization);
  }
  
  errorMessage(message: string) {
    this.snackbar.open(message, '', {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['error'],
      duration: 5000,
    });
  }

  public getAmaLoginDetails(jsonData: object): Observable<AccountOutput> {
    let headers = this.setHeaders();
    return this._http.post(this.config.ServiceUrl + '/amalogin', jsonData, { headers });
  }

  // Login Services
  rc_navigateApplication(pass: string, username: string, verificationKey: string, amaLoginResponse: object) {
    let resp = amaLoginResponse[0];
    let product_name = resp.website_product_name
    var uuId = resp.session_id;  
    if (resp.multi_logins_allowed) {
      this.sessionId = 'multi_logins_allowed';         
    } else {
      this.sessionId = resp.session_id;
    }
    if (product_name === 'RCClaimAssist') {
      let url = this.config.RCCAUrl + '/?sid=' + this.sessionId + '&uid=' + uuId;
      window.location.replace(url);
    } else if (product_name === 'RCNDCXWALK') {
      let url = this.config.RcNdcXwalkUrl + '/?sid=' + this.sessionId + '&uid=' + uuId;
      window.location.replace(url);
    } else if (product_name === 'RCCALite') {
      let url = this.config.RCCALiteUrl + '/?sid=' + this.sessionId + '&uid=' + uuId;
      window.location.replace(url);
    } else {
      this.router.navigate(['/layout']);
    }
  }
  
  username_lookup(username: string) {
    let headers = this.setHeaders();
    return this._http.post(this.config.ServiceRootUrl + '/amalogin/lookup', { 'username': username }, { headers, observe: 'response' })
  }

//Forgot Password services
  initiateForgotPassword(jsonData: object) {
    let headers = this.setHeaders();
    return this._http.post(this.config.ServiceUrl + '/forgotPassword', jsonData, { headers })
  }

  public addAccount(jsonData: object): Observable<object> {
    let headers = this.setHeaders();
    return this._http.post(this.config.ServiceRootUrl + "/account/addAccount", jsonData, { headers: headers });
  }

  public verifyAccount(jsonData: object): Observable<object> {
    let headers = this.setHeaders();
    return this._http.post(this.config.ServiceRootUrl + "/account/account_verif_confirm", jsonData, { headers: headers });
  }

  public lookupOrg(org_id: string): Observable<object> {
    let headers = this.setHeaders();
    return this._http.post(this.config.ServiceRootUrl + "/amalogin/lookuporg", { 'org_id': org_id }, { headers, observe: 'response' })
  }

}
