import {
    ChangeDetectorRef, Directive,
    ElementRef,
    HostListener,
    Input
} from '@angular/core';

@Directive({
    selector: '[numeric]',
})

export class OnlyNumericDirective {

    @Input() public decimals: number = 0;
    @Input('numeric') private params: string;

    private check(value: string, decimals: number): boolean {
        if (value) {
            let exp = this.params ? value.length === 1 && (value.indexOf('.') !== -1 || value.indexOf('-') === 0) :
                value.length === 1 && value.indexOf('.') !== -1;
            if (exp) {
                return true;
            } else if ((value.length === 1 && value === '0')) {
                return false;
            } 
            if (value.length === 1 && value.indexOf('-') !== -1) {
                return false;
            }
            let regExpString: string = '^-?\\s*((\\d+(\\.\\d{0,6})?)|((\\d*(\\.\\d{1,6}))))\\s*$';
            let i: boolean;
            if (String(value).match(new RegExp(regExpString))) {
                i = true;
            }
            else {
                i = false;
            }
            return i;
        }
    }

    private specialKeys: string[] = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'Left', 'Right', 'Del', 'ArrowRight', 'Delete'];

    constructor(
        private el: ElementRef,
        private cdr: ChangeDetectorRef,
    ) {
    }

    @HostListener('keydown', ['$event'])
    public onKeyDown(event: KeyboardEvent): void {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        let current: string = this.el.nativeElement.value.trim();
        let next: string;
        if (current && current.indexOf('-') === -1 && event.key.indexOf('-') !== -1 && this.params) {
            next = event.key.concat(current);
        } else {
            next = current ? current.concat(event.key) : event.key;
        }

        if (!this.cdr['destroyed']) this.cdr.detectChanges();
        let inValid = /\s/;
        if (inValid.test(next)) {
            event.preventDefault();
        } else if (next && !this.check(next, this.decimals)) {
            event.preventDefault();
        }
    }

}
