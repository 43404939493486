import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { GlobalNotesOutPut } from 'src/app/main/landing-page/models';
import { AccountOutput } from 'src/app/shared/models';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./../alert-page/alert-page.component.scss'],
})
export class AlertsComponent implements OnInit {

  public accountDetails: AccountOutput;
  public showAPcards: boolean = false;
  public showWPcards: boolean = false;
  public showDCcards: boolean = false;
  public showNAcards: boolean = false;
  public showCRcards: boolean = false;
  public showNCcards: boolean = false;
  public showPharmaAdvcards: boolean = false;
  public ncCodeResult: GlobalNotesOutPut[] = [];
  public wpCodeResult: GlobalNotesOutPut[] = [];
  public apCodeResult: GlobalNotesOutPut[] = [];
  public dcCodeResult: GlobalNotesOutPut[] = [];
  public naCodeResult: GlobalNotesOutPut[] = [];
  public crCodeResult: GlobalNotesOutPut[] = [];
  public showMsgTrailAlertExpired: boolean = false;

  @Input() public allPACodeResult: GlobalNotesOutPut[] = [];
  @Input() public allGACodeResult: GlobalNotesOutPut[] = [];
  @Input() public allCNCodeResult: GlobalNotesOutPut[] = [];

  constructor(
    private router: Router,
  ) { }

  public ngOnInit(): void {
    this.accountDetails = JSON.parse(sessionStorage.getItem('accountDetails'));
    this.sortCodes();
  }

  private sortCodes():void{
    this.allGACodeResult.forEach(obj => {
      switch (obj.reason_code) {
        case 'NC': this.ncCodeResult.push(obj); break;
        case 'WP': this.wpCodeResult.push(obj); break;
        case 'AP': this.apCodeResult.push(obj); break;
        case 'DC': this.dcCodeResult.push(obj); break;
        case 'NA': this.naCodeResult.push(obj); break;
        case 'CR': this.crCodeResult.push(obj); break;
      }
    });
  }

  public setDataPACode(allPACodeResult: GlobalNotesOutPut[]):void{
    this.allPACodeResult = allPACodeResult;
  }
  public setDataGACode(allGACodeResult: GlobalNotesOutPut[]):void{
    this.allGACodeResult = allGACodeResult;
    this.sortCodes();
  }
  public setDataCNCode(allCNCodeResult: GlobalNotesOutPut[] ):void{
    this.allCNCodeResult = allCNCodeResult;
  }

  private isTrail(): { status: boolean, allowed: number } {
    if (this.accountDetails.searches_performed >= this.accountDetails.searches_allowed) {
      return { status: true, allowed: this.accountDetails.searches_allowed };
    }
    return { status: false, allowed: this.accountDetails.searches_allowed };
  }

  private navigationObj(code: GlobalNotesOutPut, gnId: number, paGnId: number): NavigationExtras {
    return {
      queryParams: {
        rcId: code.rc_id,
        searchType: 'R',
        reimbCode: code.reimb_code,
        searchTerm: code.reimb_code,
        searchTexts: code.reimb_code,
        alertRequired: true,
        gn_id: gnId,
        paGnId: paGnId,
      },
    };
  }

  public redirect(code: GlobalNotesOutPut, callFrom: string): void {
    this.isTrail();
    //Time bieng commented
    // if (isTrailObj.status === true) {
    //   this.showMsgTrailAlertExpired = !this.showMsgTrailAlertExpired;
    //   return;
    // }

    const filteredGA = this.allGACodeResult.filter(obj => obj.reimb_code === code.reimb_code);
    const filteredPA = this.allPACodeResult.filter(obj => obj.reimb_code === code.reimb_code);

    if (callFrom === 'PA' && filteredGA && filteredGA.length > 0) {
      if (code.noc_or_perm === 'P') {
        let navigationExtras: NavigationExtras = this.navigationObj(code, filteredGA[0].gn_id, code.gn_id);
        this.router.navigate(['/layout/jcard/drugCard'], navigationExtras);
      } else {
        let navigationExtras: NavigationExtras = this.navigationObj(code, filteredGA[0].gn_id, code.gn_id);
        this.router.navigate(['/layout/noc'], navigationExtras);
      }
    } else if (callFrom === 'GA' && filteredPA && filteredPA.length > 0) {
      if (code.noc_or_perm === 'P') {
        let navigationExtras: NavigationExtras = this.navigationObj(code, code.gn_id, filteredPA[0].gn_id);
        this.router.navigate(['/layout/jcard/drugCard'], navigationExtras);
      } else {
        let navigationExtras: NavigationExtras = this.navigationObj(code, code.gn_id, filteredPA[0].gn_id);
        this.router.navigate(['/layout/noc'], navigationExtras);
      }
    } else {
      if (code.noc_or_perm === 'P') {
        let navigationExtras: NavigationExtras = callFrom === 'PA' ?
          this.navigationObj(code, null, code.gn_id) : this.navigationObj(code, code.gn_id, null);
        this.router.navigate(['/layout/jcard/drugCard'], navigationExtras);
      } else {
        let navigationExtras: NavigationExtras = callFrom === 'PA' ?
          this.navigationObj(code, null, code.gn_id) : this.navigationObj(code, code.gn_id, null);
        this.router.navigate(['/layout/noc'], navigationExtras);
      }
    }
  }

  private navigationAlert(): NavigationExtras {
    return {
      queryParams: {
        alertTabSelected: true,
      },
    };
  }

  public redirectToAlert(): void {
    let navigationAlert: NavigationExtras = this.navigationAlert();
    this.router.navigate(['/layout/resources/newsletters/alertHistory'], navigationAlert);
  }

}
