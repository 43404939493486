import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthorizationService } from '../services/authorization.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthorizationService
    ) {}
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean {
        const isValidUser = sessionStorage.getItem('isValidUser');
        if(isValidUser === 'true')
        {
            return true;
        }
    }
}
