import {
    ChangeDetectionStrategy, Component, OnDestroy, OnInit
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeedbackComponent } from '../feedback/feedback.component';

@Component({
    selector: 'need-help',
    templateUrl: './need-help.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class NeedHelpComponent implements OnInit, OnDestroy {
    constructor(
        private modalService: NgbModal,
    ) { }

    public ngOnInit(): void {
        //
    }


    public openFeedbackModal(): void {
        this.modalService.open(FeedbackComponent,
            { windowClass: 'is-feedback-modal', backdrop: 'static', keyboard: false }
        );
    }

    public ngOnDestroy(): void {
        this.modalService.dismissAll();
    }
}
