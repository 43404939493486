import { HttpClient } from '@angular/common/http';
import {
    AfterViewInit, ChangeDetectorRef, Component,
    OnInit, ViewChild
} from '@angular/core';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { AccountOutput } from '../../../../shared/models';
import { EnvironmentData } from '../../../../shared/models/environment.data.model';
import { Logger } from '../../../../shared/services/logger.service';
import { CommonSharedService } from '../../../../shared/services/shared.service';
import { GlobalNotesOutPut } from '../../../landing-page/models';
import { LandingPageService } from '../../../landing-page/services';
import { AlertsComponent } from '../alerts/alerts.component';

@Component({
  selector: 'app-alert-page',
  templateUrl: './alert-page.component.html',
  styleUrls: ['./alert-page.component.scss'],
})
export class AlertPageComponent implements OnInit,AfterViewInit {

  public accountDetails: AccountOutput;
  public notificationHtml: string;
  public allPACodeResult: GlobalNotesOutPut[] = [];
  public allGACodeResult: GlobalNotesOutPut[] = [];
  public allCNCodeResult: GlobalNotesOutPut[] = [];
  public config: EnvironmentData;
  public HtmlBasePath: string;
  @ViewChild('alretTab', { static: false }) private alertsComponent: AlertsComponent;
  @ViewChild('notificationTabset', { static: false }) private notificationTabset: NgbTabset;

  constructor(
    private landingPageService: LandingPageService,
    private cdr: ChangeDetectorRef,
    private logger: Logger,
    private http: HttpClient,
    private sharedService: CommonSharedService,
  ) {
  }
  public ngAfterViewInit(): void {
    if (this.notificationTabset) {
      this.notificationTabset.select('alerts');
    }
  }

  public ngOnInit(): void {
    this.config = JSON.parse(sessionStorage.getItem('config'));
    this.accountDetails = JSON.parse(sessionStorage.getItem('accountDetails'));
    this.getProductUpdates();
    this.getAllPharmaAdvisory('PA');
    this.getAllGlobalNotes('GA');
    this.getAllGlobalNotesCN('CN');
    
    
  }

  public getProductUpdates(): void {
    this.HtmlBasePath = this.config.HtmlBasePath;
    this.http.get(this.HtmlBasePath+'NotificationContent.html', {responseType: 'text'}).subscribe(data => {
    	this.notificationHtml = data;
	  });
  }

  public getAllPharmaAdvisory(searchType: string): void {
    this.landingPageService.getGlobalAlertData(searchType).subscribe(res => {
      this.allPACodeResult = Object.assign([], res);
      this.sharedService.PropagatePA(this.allPACodeResult);
      if(this.alertsComponent){
        this.alertsComponent.setDataPACode( this.allPACodeResult);
      }
      if (!this.cdr['destroyed']) this.cdr.detectChanges();
    }, (err: Response) => {
      this.logger.error(err['error'].message);
    });
  }

  public getAllGlobalNotes(searchType: string): void {
    this.landingPageService.getGlobalAlertData(searchType).subscribe(res => {
      this.allGACodeResult = Object.assign([], res);
      this.sharedService.PropagateGA(this.allGACodeResult);
      if(this.alertsComponent){
        this.alertsComponent.setDataGACode( this.allGACodeResult);
      }
      if (!this.cdr['destroyed']) this.cdr.detectChanges();
    }, (err: Response) => {
      this.logger.error(err['error'].message);
    });
  }


  public getAllGlobalNotesCN(searchType: string): void {
    this.landingPageService.getGlobalAlertData(searchType).subscribe(res => {
      this.allCNCodeResult = Object.assign([], res);
      if(this.alertsComponent){
        this.alertsComponent.setDataCNCode( this.allCNCodeResult);
      }
      if (!this.cdr['destroyed']) this.cdr.detectChanges();
    }, (err: Response) => {
      this.logger.error(err['error'].message);
    });
  }

}
