import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  panelOpenState = false;
  public error: boolean = false;
  public errMsg: string = '';
  public warn: boolean = false;

  ContactForm = this.fb.group({
    intrestedIn: [null, Validators.required],
    name: [null, Validators.required],
    company: [null, Validators.required],
    email: [null, Validators.required],
    phone: [null, Validators.required],
    message: [null]
  })
  constructor(private fb: FormBuilder,
    public regService: RegistrationService
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    this.errMsg = '';
    this.error = false;
    this.warn = false
    let pattern: string = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,9}$"
    let email: string = this.ContactForm.controls.email.value;
    if (this.ContactForm.invalid) {
      this.warn = true;
      this.errMsg = "One or more fields have an error. Please check and try again.";
    } else if (email && !email.match(pattern)) {
      this.warn = true;
      this.errMsg = "Email format is incorrect.";
    } else if (this.ContactForm.controls.phone.value.length !== 10) {
      this.warn = true;
      this.errMsg = "Phone number format is incorrect.";
    } else {
      let feedbackJsonData = {
        "firstName": this.ContactForm.controls.name.value,
        "lastName": "",
        "email": email,
        "phoneNumber": this.ContactForm.controls.phone.value,
        "help_you": this.ContactForm.controls.intrestedIn.value,
        "comment": this.ContactForm.controls.message.value,
        "companyName": this.ContactForm.controls.company.value,
        "accountID": "",
        "address": "",
        "city": "",
        "state": "",
        "zip": "",
        "fax": ""
      }
      this.regService.sendFeedback(feedbackJsonData).subscribe(response => {
        if (response["status"] == "0") {
          this.warn = true;
          this.errMsg = "Your message has been sent. We will get back to you with a response shortly.";
          this.ContactForm.reset();
        } else {
          this.error = true;
          this.errMsg = "There was an error trying to send your message. Please try again later.";
        }

      }, () => {
        this.error = true;
        this.errMsg = "There was an error trying to send your message. Please try again later.";
      });
    }
   }
}
