import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { UserIdleModule } from 'angular-user-idle';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageService } from './main/landing-page/services';
import { AlertPageComponent, AlertsComponent, IdleTimeoutComponent, NotificationsComponent } from './main/layout/alerts';
import { FeedbackComponent } from './main/layout/feedback';
import { FooterComponent } from './main/layout/footer/footer.component';
import { GlobalSearchComponent } from './main/layout/global-search/global-search.component';
import { HeaderComponent } from './main/layout/header/header.component';
import { LayoutComponent } from './main/layout/layout.component';
import { NeedHelpComponent } from './main/layout/need-help';
import { QuickSearchComponent } from './main/layout/quick-search/quick-search.component';
import { SideNavComponent } from './main/layout/side-nav/side-nav.component';
import { AboutComponent } from './main/unauth/about/about.component';
import { AccessDeniedComponent } from './main/unauth/access-denied/access-denied.component';
import { AccountCreationSuccessfulComponent } from './main/unauth/account-creation-successful/account-creation-successful.component';
import { ContactComponent } from './main/unauth/contact/contact.component';
import { CustomerAccountCreationComponent } from './main/unauth/customer-account-creation/customer-account-creation.component';
import { CustomerRegistrationComponent } from './main/unauth/customer-registration/customer-registration.component';
import { DataModulesComponent } from './main/unauth/data-modules/data-modules.component';
import { ForgotPasswordComponent } from './main/unauth/forgot-password/forgot-password.component';
import { FreeTrialComponent } from './main/unauth/free-trial/free-trial.component';
import { AfterCarouselPagesComponent } from './main/unauth/homepage/after-carousel-pages/after-carousel-pages.component';
import { Banner1Component } from './main/unauth/homepage/carousel/banner1/banner1.component';
import { Banner2Component } from './main/unauth/homepage/carousel/banner2/banner2.component';
import { Banner3Component } from './main/unauth/homepage/carousel/banner3/banner3.component';
import { Banner4Component } from './main/unauth/homepage/carousel/banner4/banner4.component';
import { CarouselComponent } from './main/unauth/homepage/carousel/carousel.component';
import { HomepageComponent } from './main/unauth/homepage/homepage.component';
import { InvalidSignatureComponent } from './main/unauth/invalid-signature/invalid-signature.component';
import { KeyFeaturesComponent } from './main/unauth/key-features/key-features.component';
import { LoginErrorComponent } from './main/unauth/login-error/login-error.component';
import { LoginComponent } from './main/unauth/login/login.component';
import { LogoutComponent } from './main/unauth/logout/logout.component';
import { UnauthBlogComponent } from './main/unauth/unauth-blog/unauth-blog.component';
import { UnauthFooterComponent } from './main/unauth/unauth-footer/unauth-footer.component';
import { UnauthHeaderComponent } from './main/unauth/unauth-header/unauth-header.component';
import { UnauthPrivacyPolicyComponent } from './main/unauth/unauth-privacy-policy/unauth-privacy-policy.component';
import { UnauthTermsAndConditionsComponent } from './main/unauth/unauth-terms-and-conditions/unauth-terms-and-conditions.component';
import { UnauthComponent } from './main/unauth/unauth.component';
import { WebsiteAccessibilityComponent } from './main/unauth/website-accessibility/website-accessibility.component';
import { CustomDateParserFormatter } from './shared/common/component/date-control/datepicker-formatter';
import { SharedModule } from './shared/common/module/shared.module';
import { GlobalHttpInterceptorService } from './shared/interceptor/globalHttpInterceptorService';
import { MaterialModule } from './shared/modules/material.module';
import { CommonService } from './shared/services/common.service';
import { GlobalService } from './shared/services/global.service';
import { Logger } from './shared/services/logger.service';
import { CommonSharedService } from './shared/services/shared.service';

@NgModule({
  declarations: [
    AppComponent,
    UnauthComponent,
    HomepageComponent,
    UnauthHeaderComponent,
    UnauthFooterComponent,
    CarouselComponent,
    AfterCarouselPagesComponent,
    Banner1Component,
    Banner2Component,
    Banner3Component,
    Banner4Component,
    UnauthTermsAndConditionsComponent,
    UnauthPrivacyPolicyComponent,
    WebsiteAccessibilityComponent,
    AboutComponent,
    ContactComponent,
    KeyFeaturesComponent,
    DataModulesComponent,
    FreeTrialComponent,
    CustomerRegistrationComponent,
    CustomerAccountCreationComponent,
    AccountCreationSuccessfulComponent,
    LoginComponent,
    LogoutComponent,
    ForgotPasswordComponent,
    UnauthBlogComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    SideNavComponent,
    AlertPageComponent,
    AlertsComponent,
    NotificationsComponent,
    NeedHelpComponent,
    FeedbackComponent,
    GlobalSearchComponent,
    QuickSearchComponent,
    IdleTimeoutComponent,
    UnauthBlogComponent,
    LoginErrorComponent,
    AccessDeniedComponent,
    InvalidSignatureComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    NgbModule,
    SharedModule,
    NgxMaskModule.forRoot(),
    UserIdleModule.forRoot({}),
    MatCarouselModule.forRoot()
  ],
  providers: [
    GlobalService,
    CommonService,
    Logger,
    LandingPageService,
    CommonSharedService,
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents: [FeedbackComponent, IdleTimeoutComponent],
})
export class AppModule { }
