import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-after-carousel-pages',
  templateUrl: './after-carousel-pages.component.html',
  styleUrls: ['./after-carousel-pages.component.scss']
})
export class AfterCarouselPagesComponent implements OnInit {
  slideIndex = 0;
  activeId: number = 1;
  constructor() { }

  ngOnInit() {
  }

}
