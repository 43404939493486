import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-creation-successful',
  templateUrl: './account-creation-successful.component.html',
  styleUrls: ['./account-creation-successful.component.scss']
})
export class AccountCreationSuccessfulComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
