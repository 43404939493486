import {
    Directive,
    ElementRef,
    HostListener
} from '@angular/core';

@Directive({
    selector: '[appInputMask]',
})
export class InputMaskDirective {
    private specialKeys: string[] = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '=',
        '+', '_', ';', ':', '<', '>', '?', '"', '.', ',', '|', '\\', '\''];

    constructor(
        private el: ElementRef,
    ) {
    }

    @HostListener('input', ['$event'])
    public onKeyDown(event: KeyboardEvent): string {
        const input = event.target as HTMLInputElement;
        //if(event.eventPhase !== 2)
        //{
            if (this.specialKeys.indexOf(input.value.trim().substring(input.value.length - 1)) > -1) {
                return input.value = '';
            } else if (input.value.substring(input.value.trim().length - 1).match(/^[a-zA-Z]+$/)) {
                return input.value = '';
            }
            else {
                let trimmed = '';
                if (input.value.trim().replace(/\s+/g, '').length <= 5) {
                    trimmed = input.value.trim().replace(/\s+/g, '').slice(0, input.value.trim().indexOf('/') === -1 ? 4 : 5);
                } else if (input.value.trim().replace(/\s+/g, '').length > 5) {
                    trimmed = input.value.trim().replace(/\s+/g, '').slice(0, input.value.trim().indexOf('/') === -1 ? 9 : 10);
                }
    
                if (trimmed.length > 2 && trimmed.length <= 5) {
                    return (input.value = `${trimmed.slice(0, 2)}/${trimmed.slice(trimmed.indexOf('/') === -1 ? 2 : 3)}`);
                } else if (trimmed.length > 5) {
                    return (input.value = `${trimmed.slice(0, 2)}/${trimmed.slice(trimmed.indexOf('/') === -1 ? 2 : 3, 5)}/`+
                    `${trimmed.slice(trimmed.indexOf('/') === -1 ? 5 : 6)}`);
                }
            }
        //}
        

    }
}
