import {
    ChangeDetectorRef, Directive,
    ElementRef,
    HostListener,
    Input
} from '@angular/core';

@Directive({
    selector: '[integer]',
})

export class IntegerOnlyDirective {

    @Input() public decimals: number = 0;

    private check(value: string, decimals: number): boolean {
        if (value) {
            if(value.length === 1 && value.indexOf('-') !== -1){
                return false;
            }
            let regExpString: string = '^\\s*((\\d+(\\.\\d{0,6})?)|((\\d*(\\.\\d{1,6}))))\\s*$';
            let i: boolean;
            if (String(value).match(new RegExp(regExpString))) {
                i = true;
            }
            else {
                i = false;
            }
            return i;
        }
    }

    private specialKeys: string[] = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];

    constructor(
        private el: ElementRef,
        private cdr: ChangeDetectorRef,
        ) {
    }

    @HostListener('keydown', ['$event'])
    public onKeyDown(event: KeyboardEvent): void {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        let current: string = this.el.nativeElement.value.trim();
        let next: string ;
        next = current ? current.concat(event.key):event.key;
        // if(current && current.indexOf('-')  === -1) {
        //     if(event.key.indexOf('-') !== -1){
        //         next = event.key.concat(current);
        //     }
        //     else {
        //         next = current ? current.concat(event.key):event.key;
        //     }
        // } else {

        //     next = current ? current.concat(event.key):event.key;
        // }
       
        //if (!this.cdr['destroyed']) this.cdr.detectChanges();
        if (next && !this.check(next, this.decimals)) {
            event.preventDefault();
        }
    }

}
