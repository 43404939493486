import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauth-footer',
  templateUrl: './unauth-footer.component.html',
  styleUrls: ['./unauth-footer.component.scss']
})
export class UnauthFooterComponent implements OnInit {
// public showSignUp: boolean = true;
@Input() showSignUp: boolean = true;
public curentyear: number;
public previousyear: number;
constructor() { const i = 0; }
public ngOnInit(): void {
  this.curentyear = new Date().getFullYear();
  this.previousyear = new Date().getFullYear() - 1;
}

}
