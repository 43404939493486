import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountOutput } from 'src/app/shared/models/account-output.data.model';
import { EnvironmentData } from 'src/app/shared/models/environment.data.model';
import { AuthorizationService } from '../../../../shared/services/authorization.service';
import { Logger } from '../../../../shared/services/logger.service';

@Component({
  selector: 'app-idle-timeout',
  templateUrl: './idle-timeout.component.html',
})
export class IdleTimeoutComponent implements OnInit {

  public accountDetails: AccountOutput;
  public userName: string = '';
  public config: EnvironmentData;
  public minute: number;
  @Input() public isSessionTimeout: boolean = false;
  constructor(
    private authService: AuthorizationService,
    private router: Router,
    private logger: Logger) {
    const i = 0;
  }

  public ngOnInit(): void {
    this.config = JSON.parse(sessionStorage.getItem('config'));
    this.accountDetails = JSON.parse(sessionStorage.getItem('accountDetails'));
    this.minute = this.config.Idle / 60;
  }

  public closeIdleTimeoutModel(): void {
    const sessionId = window.name ? JSON.parse(window.name)['session_id'] : '';
    if (sessionId) {
      this.authService.deleteSession(sessionId).subscribe(res => {
        window.name = '';
        sessionStorage.removeItem('isValidUser');
        sessionStorage.clear();
        this.router.navigate(['/logout']);
        // window.location.replace(this.config.LogOutPage);
      }, (err: Response) => {
        this.logger.error(err['error'].message);
      });
    } else {
      window.name = '';
      sessionStorage.removeItem('isValidUser');
      sessionStorage.clear();
      this.router.navigate(['/logout']);
      // window.location.replace(this.config.LogOutPage);
    }
  }

}
