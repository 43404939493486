import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-gc-element-exceed',
  templateUrl: './gc-element-exceed.component.html',
  styleUrls: ['./gc-element-exceed.component.scss'],
})
export class GcElementExceedComponent implements OnInit {

  constructor(
    private activeModal: NgbActiveModal,
  ) {const i=0; }

  public ngOnInit():void {
    const i = 0;
  }

  public closeExternalLinkModal(): void {
    this.activeModal.close();
  }

}
