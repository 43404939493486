import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AccountOutput } from 'src/app/shared/models/account-output.data.model';
import { EnvironmentData } from 'src/app/shared/models/environment.data.model';
import { AuthorizationService } from '../../../shared/services/authorization.service';
import { CommonService } from '../../../shared/services/common.service';
import { Logger } from '../../../shared/services/logger.service';

@Component({
  selector: 'side-nav',
  styleUrls: ['./side-nav.component.scss'],
  templateUrl: './side-nav.component.html',
})
export class SideNavComponent implements OnInit {
  public showGlobalSearchDropdown: boolean;
  public showFullMenu: boolean = true;
  public accountDetails: AccountOutput;
  public config: EnvironmentData;
  public showNavigationAccessCard:boolean=false;
  public showTopJcardCard: boolean=false;
  public showTopNDCCard: boolean=false;
  public showTopRecentSearch: boolean=false;
  public showNOCcode: boolean=false;
  public showFavorite: boolean=false;
  public showComparePage: boolean=false;
  public feeSchedular: boolean=false;
  @ViewChild('searchDropdown', {static: false}) private searchDropdown: ElementRef;
  @ViewChild('searchLi', {static: false}) private searchLi: ElementRef;
  @ViewChild('feeSchedularLoginErrorPopup', { static: true }) public feeSchedularLoginErrorPopup: string;
  public configuration:EnvironmentData;
  public headers: HttpHeaders = null;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private authService: AuthorizationService,
    private commonService: CommonService,
    private logger: Logger,
    private modalService: NgbModal,
    private _http: HttpClient,
  ) { }

  public ngOnInit(): void {
    this.config = JSON.parse(sessionStorage.getItem('config'));
    this.accountDetails = JSON.parse(sessionStorage.getItem('accountDetails'));
    this.checkRoute();
    this.router.events.subscribe((val) => {
      this.checkRoute();
    });
    this.decideFeatureOnOff(this.accountDetails);
    let fsUser = this.accountDetails.fs_user;
    if (fsUser === '1') {
      this.feeSchedular = true;
    }
  }

  private decideFeatureOnOff(accountDetails: AccountOutput): void {
    accountDetails.features.split(',').forEach((value, key) => {
      if(value.toUpperCase() === 'NC'){
        this.showNavigationAccessCard = true;
      }
      if(value.toUpperCase() === 'NJ'){
        this.showTopJcardCard = true;
      }
      if(value.toUpperCase() === 'NN'){
        this.showTopNDCCard = true;
      }
      if(value.toUpperCase() === 'NR'){
        this.showTopRecentSearch = true;
      }
      if(value.toUpperCase() === 'ND'){
        this.showNOCcode = true;
      }
      if(value.toUpperCase() === 'NF'){
        this.showFavorite = true;
      }
      if(value.toUpperCase() === 'OC'){
        this.showComparePage = true;
      }
    });
  }

  public checkRoute(): void {
    if (window.location.hash !== '#/layout') {
      this.showFullMenu = false;
    } else {
      this.showFullMenu = true;
    }
    if (!this.cdr['destroyed']) this.cdr.detectChanges();
  }

  public uitracking(featureCode:string):void{

    var requestUITrack = {
         'account_id':this.accountDetails.account_id,
         'service_used': 'UITRACK',
         'search_criteria': featureCode,
     };
    
     this.commonService.uiTrakcing(requestUITrack).catch(err => {
      this.logger.error(err['error'].message);
    });
  }

  public quickSearchClick(action: string): void {
    if (action !== null ) {
      if (action === 'SHOW') {
        this.searchDropdown.nativeElement.style.display = 'block';
        this.searchLi.nativeElement.classList.add('bg-brand-dark');
      } else {
        this.searchDropdown.nativeElement.style.display = 'none';
        this.searchDropdown.nativeElement.style.display = '';
        this.searchLi.nativeElement.classList.remove('bg-brand-dark');
      }
    }
  }

  public openFeeSchedular(): void {
    var jsonObject = { user_type: 'U'};
    this.setSession(jsonObject).subscribe(data => {
      this.logger.info(data);
      if (data['session'] !== null) {
        sessionStorage.setItem('fsSessionId', data['session']);
      }
      if (data['status'] === '0') {
        window.open((this.config.FeeSchedularURL + '/#/?sid=' + data['session']),'_blank');
      } else if (data['status'] === '3') {
        this.modalService.open(this.feeSchedularLoginErrorPopup,
          { windowClass: 'welcomeRCCA', centered: true, backdrop: 'static', keyboard: false, size: 'md' });
      } else {
        let errorWording: string = '';
        if (data['status'] === '1') {
          errorWording = 'You are not authorized';
        } else if (data['status'] === '2') {
          this.modalService.open(this.feeSchedularLoginErrorPopup,
            { windowClass: 'welcomeRCCA', centered: true, backdrop: 'static', keyboard: false, size: 'md' });
        } else {
          errorWording = 'Unknown Error';
        }
        alert(errorWording);
      }
    });
  }

  private setSession(jsonObject:object) : Observable<object>{
    let headers =  this.setHeaders();
    return this._http.post(this.config.FeeSchedularService + 'sess/SetSession',	jsonObject,{headers});
  }

  private killSession() : Observable<object>{
    let headers =  this.setFSHeaders();
    return this._http.post(this.config.FeeSchedularService + 'sess/KillSession', {}, {headers});
  }

  public killSessionAndOpen(): void {
    this.killSession().subscribe(data => {
      this.logger.info(data);
      this.openFeeSchedular();
    });
  }

  private setHeaders(): HttpHeaders {
    this.config = JSON.parse(sessionStorage.getItem('config'));
    let apiKey = sessionStorage.getItem('ak')
    return this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', apiKey)
      .set('Authorization', sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') : this.config.Authorization);
  }

  private setFSHeaders():HttpHeaders{
    this.configuration = JSON.parse(sessionStorage.getItem('config'));
    let apiKey = sessionStorage.getItem('ak')
    return this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', apiKey)
      .set('Authorization',sessionStorage.getItem('fsSessionId')?sessionStorage.getItem('fsSessionId'):this.configuration.Authorization);
  }
}
