
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) { }

  public transform(value: string, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    if (value) {
      value = value.replace(new RegExp('(' + type + ')', 'gi'), '<span class="text-org">$1</span>');
    }
    return value;
  }

}

