import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'pricing-dropdown',
  templateUrl: './pricing-dropdown.component.html',
})
export class PricingDropdownComponent implements OnInit, OnChanges {
  public formatter: string = '';
  @Input() public dataSource: { key: number, value: string }[];
  public masterDataSource: { key: number, value: string }[];
  @Input() public selectedData: { key: number, value: string };
  @Output() public emitSelectedData: EventEmitter<{ key: number, value: string }> = new EventEmitter<{ key: number, value: string }>();
  public isFirstChange: boolean = true;
  public onUserEnterValue:Subject<string> = new Subject<string>();

  constructor() {
    this.onUserEnterValue.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(value => {
        if(this.selectedData.value){
            this.selectedData.key= Number(this.selectedData.value);
            this.dataSource.push(Object.assign({},this.selectedData));
            this.emitSelectedData.emit(this.selectedData);
        }
      });
   }

  public ngOnInit(): void {
    //
  }
  public ngOnChanges(): void {
    if (this.isFirstChange) {
      this.masterDataSource = Object.assign([], this.dataSource);
    }
  }
  public onDropDownSelect(data: { key: number, value: string }): void {
    this.isFirstChange = false;
    this.selectedData = Object.assign([], data);
    this.emitSelectedData.emit(data);
  }
  public clearSelectedData(): void {
    this.selectedData=Object.assign([], { key: null, value: '' });
    this.emitSelectedData.emit(this.selectedData);
  }
}
