import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { SearchComponent } from 'src/app/main/layout/search/search.component';
import { PricingDropdownComponent } from '../../../main/jcard-page/components/pricing-dropdown';
import { DomChangeDirective } from '../../../main/landing-page/directives/dom-change.directive';
import { GcElementExceedComponent } from '../../../shared/common/component/gc-element-exceed/gc-element-exceed.component';
import { DateControlComponent } from '../component/date-control/date-control.component';
import { SearchDropdownComponent } from '../component/search-dropdown/search-dropdown.component';
import { ToastContainerComponent } from '../component/toast-container/toast-container.component';
import { AlphabetOnlyDirective } from '../directive/alphabet-only.directive';
import { InputMaskDirective } from '../directive/date-only.directive';
import { IntegerOnlyDirective } from '../directive/integer-only.directive';
import { OnlyAlphaNumericDirective } from '../directive/only-alphanumeric.directive';
import { OnlyNumericDirective } from '../directive/only-numeric.directive';
import { ZipCodeDirective } from '../directive/zip-code.directive';
import { HighlightPipe } from '../pipe/highlight.pipe';
import { SanitizePipe } from '../pipe/sanitize.pipe';
@NgModule({
  declarations: [
    ToastContainerComponent,
    OnlyNumericDirective,
    OnlyAlphaNumericDirective,
    SearchDropdownComponent,
    SearchComponent,
    HighlightPipe,
    DateControlComponent,
    DomChangeDirective,
    SanitizePipe,
    PricingDropdownComponent,
    IntegerOnlyDirective,
    AlphabetOnlyDirective,
    GcElementExceedComponent,
    ZipCodeDirective,
    InputMaskDirective,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    NgxMaskModule.forRoot(),
  ],
  entryComponents: [GcElementExceedComponent],
  exports: [
    ToastContainerComponent,
    OnlyNumericDirective,
    OnlyAlphaNumericDirective,
    SearchDropdownComponent,
    SearchComponent,
    HighlightPipe,
    DateControlComponent,
    DomChangeDirective,
    SanitizePipe,
    PricingDropdownComponent,
    IntegerOnlyDirective,
    AlphabetOnlyDirective,
    GcElementExceedComponent,
    ZipCodeDirective,
    InputMaskDirective,
  ],
})
export class SharedModule { }
