import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './main/layout/layout.component';
import { AboutComponent } from './main/unauth/about/about.component';
import { AccessDeniedComponent } from './main/unauth/access-denied/access-denied.component';
import { AccountCreationSuccessfulComponent } from './main/unauth/account-creation-successful/account-creation-successful.component';
import { ContactComponent } from './main/unauth/contact/contact.component';
import { CustomerAccountCreationComponent } from './main/unauth/customer-account-creation/customer-account-creation.component';
import { CustomerRegistrationComponent } from './main/unauth/customer-registration/customer-registration.component';
import { DataModulesComponent } from './main/unauth/data-modules/data-modules.component';
import { ForgotPasswordComponent } from './main/unauth/forgot-password/forgot-password.component';
import { FreeTrialComponent } from './main/unauth/free-trial/free-trial.component';
import { HomepageComponent } from './main/unauth/homepage/homepage.component';
import { InvalidSignatureComponent } from './main/unauth/invalid-signature/invalid-signature.component';
import { KeyFeaturesComponent } from './main/unauth/key-features/key-features.component';
import { LoginErrorComponent } from './main/unauth/login-error/login-error.component';
import { LoginComponent } from './main/unauth/login/login.component';
import { LogoutComponent } from './main/unauth/logout/logout.component';
import { UnauthBlogComponent } from './main/unauth/unauth-blog/unauth-blog.component';
import { UnauthPrivacyPolicyComponent } from './main/unauth/unauth-privacy-policy/unauth-privacy-policy.component';
import { UnauthTermsAndConditionsComponent } from './main/unauth/unauth-terms-and-conditions/unauth-terms-and-conditions.component';
import { UnauthComponent } from './main/unauth/unauth.component';
import { WebsiteAccessibilityComponent } from './main/unauth/website-accessibility/website-accessibility.component';
import { AuthGuard } from './shared/guards/auth.guard';


const routes: Routes = [
  {
    path: '',
    component: UnauthComponent,
    children: [
      { path: '', component: HomepageComponent },
      { path: 'terms-conditions', component: UnauthTermsAndConditionsComponent },
      { path: 'privacy-policy', component: UnauthPrivacyPolicyComponent },
      { path: 'website-accessibility', component: WebsiteAccessibilityComponent },
      { path: 'features', component: KeyFeaturesComponent },
      { path: 'data-modules', component: DataModulesComponent },
      { path: 'about', component: AboutComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'free-trial', component: FreeTrialComponent },
      { path: 'customer-registration', component: CustomerRegistrationComponent },
      { path: 'customer-account-creation', component: CustomerAccountCreationComponent },
      { path: 'account-creation-successful', component: AccountCreationSuccessfulComponent },
      { path: 'login', component: LoginComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'logout', component: LogoutComponent },
      { path: 'blog', component: UnauthBlogComponent },
      { path: 'login-error', component: LoginErrorComponent },
      { path: 'access-denied-rc2', component: AccessDeniedComponent },
      { path: 'invalid-signature-rc2', component: InvalidSignatureComponent },
      { path: 'users/palogin/:key/:key1/:key2/:key3', component: HomepageComponent },
      { path: 'users/palogin/:key/:key1/:key2', component: HomepageComponent },
      { path: 'users/palogin/:key/:key1', component: HomepageComponent },
      { path: 'users/palogin/:key', component: HomepageComponent },


    ]
  },
  {
    path: 'layout',
    component: LayoutComponent, canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: 'src/app/main/landing-page#LandingPageModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'jcard',
        loadChildren: './main/jcard-page#JcardPageModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'filter',
        loadChildren: './main/filter-page#FilterPageModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'file',
        loadChildren: './main/file-download-page#FileDownloadPageModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'groupCompare',
        loadChildren: './main/group-compare-page#GroupComparePageModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'noc',
        loadChildren: './main/noc-page#NocCardPageModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'miniCards',
        loadChildren: './main/favorite-page#FavoritePageModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'filterSearch',
        loadChildren: './main/filter-page#FilterPageModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'accountInformation',
        loadChildren: './main/account-information#AccountInformationModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'preferences',
        loadChildren: './main/preferences/preferences.module#PreferencesModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'resources',
        loadChildren: './main/resources/resources.module#ResourcesModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'adminTools',
        loadChildren: './main/admin-tools#AdminToolsModule',
        canActivate: [AuthGuard],
      },
    ],
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
})
export class AppRoutingModule { }
