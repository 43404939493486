import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner4',
  templateUrl: './banner4.component.html',
  styleUrls: ['./banner4.component.scss']
})
export class Banner4Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
