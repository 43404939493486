import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountOutput } from '../../../shared/models/account-output.data.model';
import { LandingPageService } from '../../landing-page/services';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
})
export class FeedbackComponent implements OnInit {
  public feedbackForm: FormGroup;
  public feedbackSubmitted: boolean = false;
  public accountDetails: AccountOutput;
  public sortOrders: string[] = [
    'Issue found with Data',
    'Issue found with Navigation',
    'Issue found with Search',
    'Need help with Drug Card',
    'Need help with Detail Panels',
    'Need help with Crosswalk',
    'Need help with Navigation',
    'Have a suggestion',
    'Would like a training webinar',
  ];
  public selectedSortOrder: string;

  @ViewChild('feedbackSubmitMessage', { static: true }) public feedbackSubmitMessage: string;

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private feedbackService: LandingPageService) { }

  public ngOnInit(): void {
    this.accountDetails = JSON.parse(sessionStorage.getItem('accountDetails'));
    this.feedbackFormControl();
  }

  public closeFeebackPopup(): void {
    this.activeModal.close();
  }

  private feedbackFormControl(): void {
    const replacedValue = this.accountDetails.phone.toString().replace(/-/g, '');
    this.feedbackForm = this.formBuilder.group({
      firstName: [this.accountDetails.contact_first,
         Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*'), Validators.maxLength(50)])],
      lastname: [this.accountDetails.contact_last,
         Validators.compose([Validators.required, Validators.pattern('[a-zA-Z ]*'), Validators.maxLength(50)])],
      email: [this.accountDetails.email],
      phone: [replacedValue,
        Validators.compose([Validators.required, Validators.maxLength(14), Validators.pattern(/^\d*(?:\.\d{1,2})*$/)])],
      companyname: [''],
      help: [''],
      comment: [''],
      accountID: [this.accountDetails.account_id.toString()],
      fax: [this.accountDetails.fax],
      address1:[this.accountDetails.address_1],
      city: [this.accountDetails.city],
      state: [this.accountDetails.state],
      zip: [this.accountDetails.zip],
    });
  }

  public onSubmit(): void {
    this.feedbackSubmitted = true;
    if (this.feedbackForm.invalid) {
      return;
    } else {
      const feedback = {
        firstName: this.feedbackForm.value.firstName,
        lastName: this.feedbackForm.value.lastname,
        email: this.feedbackForm.value.email,
        phoneNumber: this.feedbackForm.value.phone,
				help_you: this.selectedSortOrder || '',
				comment: this.feedbackForm.value.comment,
        companyName: this.feedbackForm.value.companyname || '',
        accountID: this.feedbackForm.value.accountID,
        address: this.feedbackForm.value.address1 || '',
        city: this.feedbackForm.value.city || '',
        state: this.feedbackForm.value.state || '',
        zip: this.feedbackForm.value.zip || '',
        fax: this.feedbackForm.value.fax || '',
			};
     
      this.feedbackService.saveFeedback(feedback).subscribe(response => {
        if (response) {
          this.activeModal.close();
          this.modalService.open(this.feedbackSubmitMessage, { windowClass: 'is-feedback-modal',backdrop:true });

        } else {
          alert('We have detected that service call on site has been blocked by your Org. Please contact us at 860-563-1223 Ext.2');
        }
      });
    }
  }

  public ChangeSortOrder(newSortOrder: string): void {
    this.selectedSortOrder = newSortOrder;
  }

}
