import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { GlobalNotesOutPut } from '../models/global-notes.data.model';

@Injectable()

export class CommonSharedService {
  private propagateGANotesOutput: Subject<GlobalNotesOutPut[]> = new Subject<GlobalNotesOutPut[]>();
  private propagatePANotesOutput: Subject<GlobalNotesOutPut[]> = new Subject<GlobalNotesOutPut[]>();
  private propagateGA: Subject<GlobalNotesOutPut[]> = new Subject<GlobalNotesOutPut[]>();
  private propagatePA: Subject<GlobalNotesOutPut[]> = new Subject<GlobalNotesOutPut[]>();

  public PropagateGANotesOutput(notesOutput: GlobalNotesOutPut[]): void {
    this.propagateGANotesOutput.next(notesOutput);
  }
  public SubscribeGANotesOutput(): Observable<GlobalNotesOutPut[]> {
    return this.propagateGANotesOutput.asObservable();
  }
  public PropagatePANotesOutput(notesOutput: GlobalNotesOutPut[]): void {
    this.propagatePANotesOutput.next(notesOutput);
  }
  public SubscribePANotesOutput(): Observable<GlobalNotesOutPut[]> {
    return this.propagatePANotesOutput.asObservable();
  }

  public PropagateGA(notesOutput: GlobalNotesOutPut[]): void {
    this.propagateGA.next(notesOutput);
  }
  public SubscribeGA(): Observable<GlobalNotesOutPut[]> {
    return this.propagateGA.asObservable();
  }
  public PropagatePA(notesOutput: GlobalNotesOutPut[]): void {
    this.propagatePA.next(notesOutput);
  }
  public SubscribePA(): Observable<GlobalNotesOutPut[]> {
    return this.propagatePA.asObservable();
  }
}
