import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output
} from '@angular/core';

@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
})

export class SearchDropdownComponent implements OnInit, OnChanges {
  public formatter: string = '';

  // tslint:disable:no-any
  @Input() public dataSource: any;
  @Input() public selectedData: any = '';
  @Input() public placeholderName: string = '';
  @Input() public readonly: boolean;
  @Output() public emitSelectedData: EventEmitter<any> = new EventEmitter<any>();
  @Input() public disabled: boolean = true;
  @Input() public applyMask: boolean = false;
  @Input() public isEdit:boolean=true;
  public masterDataSource: any;
  public mask:any;
  public isFirstChange: boolean = true;
  public search: any;
  constructor() { const i = 0; }

  public ngOnInit(): void {
    //
  }
  public ngOnChanges(): void {
    if (this.isFirstChange) {
      this.masterDataSource = Object.assign([], this.dataSource);
    }
  }

  public setMask(applyMask:boolean): void{
    if(applyMask){
      this.mask = '00000-0000-00';
    } else{
      return;
    }
  }

  public onDropDownSelect(data: any): void {
    this.isFirstChange = false;
    if(data.value.indexOf('(s)') !==-1 ){
      this.selectedData = data.value.substring(0,data.value.indexOf('(s)'));
    }
    this.emitSelectedData.emit(data);
  }

  public filterDropDownData(): void {
    this.isFirstChange = false;
    if (this.selectedData) {
      this.setMask(this.applyMask);
      this.dataSource = this.masterDataSource.filter((obj) => {
        if(obj.value && obj.value.indexOf('-') !== -1){
          let val = obj.value.replace(/-/gi,'');
          return val && this.selectedData && val.toLowerCase().indexOf(this.selectedData.toLowerCase()) !== -1;
        } else {
          return obj.value && this.selectedData && obj.value.toLowerCase().indexOf(this.selectedData.toLowerCase()) !== -1;
        }
       
      });
        
      const data = {
        key: this.selectedData,
        value: this.selectedData,
      };
      this.emitSelectedData.emit(data);
    } else {
      this.dataSource = this.masterDataSource;
    }
  }
}
