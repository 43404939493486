import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./../alert-page/alert-page.component.scss'],
})
export class NotificationsComponent implements OnInit {

  @Input() public notificationHtml: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) { }
  
  public ngOnInit(): void {
    //
  }
  private navigationObj(): NavigationExtras {
    return {
      queryParams: {
        notificationTabSelected:true,
      },
    };
  }

  public redirectToNotification(): void {
    let navigationExtras: NavigationExtras = this.navigationObj();
          this.router.navigate(['/layout/resources/newsletters/notificationHistory'], navigationExtras);
  }

}
