import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-quick-search',
  templateUrl: './quick-search.component.html',
})
export class QuickSearchComponent {

  @Output() public quickSearchClick: EventEmitter<string> = new EventEmitter<string>();

  public searchClick(action: string): void{
    this.quickSearchClick.emit(action);
  }
}
