import {
    ChangeDetectorRef, Component, EventEmitter,
    HostListener, OnInit, Output
} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AccountOutput } from 'src/app/shared/models/account-output.data.model';
import { EnvironmentData } from '../../../shared/models/environment.data.model';
import { Logger } from '../../../shared/services/logger.service';
import { CommonSharedService } from '../../../shared/services/shared.service';
import { ToastService } from '../../../shared/services/toast.service';
import { GlobalNotesOutPut } from '../../landing-page/models/global-notes.data.model';
import { GlobalSearchViews } from '../../landing-page/models/global-search-views.data.model';
import { LandingPageService } from '../../landing-page/services/landing-page.service';
@Component({
  selector: 'landing-page-search',
  templateUrl: './search.component.html',
})
export class SearchComponent implements OnInit {
  public showGlobalSearchDropdown: boolean = false;
  public drugCodeResponse: GlobalSearchViews[] = [];
  public config: EnvironmentData;
  public accountDetails: AccountOutput;
  public typedValue: string = '';
  public showNoResultFoundMsg: boolean = false;
  public showGenericSearchMsg: boolean = false;
  public placeholderData: string = 'Start typing a code, Drug, Product, Diagnosis or Therapeutic Class';
  public allPharmaCodeResult: GlobalNotesOutPut[] = [];
  public allCodeResult: GlobalNotesOutPut[] = [];
  private searchFieldChanged: Subject<string> = new Subject<string>();
  public userRecentSearches: string[] = [];
  public usersLastTypedValueSearch: string[] = [];
  public showByDdcICD10:boolean=false;
  public showSearchByTheraputic: boolean = false;
  private subscription: Subscription;
  @Output() public searchClick: EventEmitter<string> = new EventEmitter<string>();
  private clickedInside: boolean = false;
  private clicked: boolean = false;
  private wasInside: boolean = false;

  constructor(
    private landingService: LandingPageService,
    private sharedService: CommonSharedService,
    private logger: Logger,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private toastService: ToastService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.subscription = this.sharedService.SubscribePA().subscribe(paData => {
      this.setPAData(paData);
  });
  this.subscription = this.sharedService.SubscribeGA().subscribe(gaData => {
    this.setGAData(gaData);
});

  }

  public ngOnInit(): void {
    this.accountDetails = JSON.parse(sessionStorage.getItem('accountDetails'));
    this.config = JSON.parse(sessionStorage.getItem('config'));
    this.decideFeatureONOFF();
    this.searchFieldChanged.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(newText => {
      if (newText.length >= 3) {
        this.callGlobalSearchViews(this.typedValue, 'I', 0);
        if (!this.cdr['destroyed']) this.cdr.detectChanges();
      }
    });
    if (this.userRecentSearches === undefined) {
      this.userRecentSearches = [];
    }
    this.userRecentSearches = localStorage.getItem('key') ? JSON.parse(localStorage.getItem('key')) : [];
    this.usersLastTypedValueSearch = this.userRecentSearches ? this.userRecentSearches : [];
  }

  public userRecentSearchCall(hcpcsCode: string): void {
    this.clickedInside = true;
    let index = this.usersLastTypedValueSearch.indexOf(hcpcsCode);
    this.typedValue = this.usersLastTypedValueSearch[index];
    this.redirectToFilterSearchPage();
  }

  public decideFeatureONOFF(): void {
    this.accountDetails.features.split(',').forEach((value, key) => {
      if (value === 'GI') {
        this.showByDdcICD10 = true;
      }
      if (value === 'GT') {
        this.showSearchByTheraputic = true;
      }
    });
  }

  public callOnKeyUp(e: Event): void {
    this.searchBoxFocus();
    this.showGenericSearchMsg = false;
    const searchTexts = this.config.AvoidingSearchText.split(',');
    let searchTextsExists = false;
    searchTexts.forEach(objcallOnKeyUp => {
      if (!searchTextsExists) {
        if (objcallOnKeyUp === this.typedValue) {
          searchTextsExists = true;
        }
      }
    });
    if (searchTextsExists) {
      this.showGenericSearchMsg = true;
      return;
    }
    if (this.typedValue.trim().toString().length >= 3) {
      if (e['which'] === 13) {
        this.redirectToFilterSearchPage();
      }
    } else {
      this.drugCodeResponse = [];
      this.showNoResultFoundMsg = false;
    }

  }
  public onchange(): void {
    this.showGenericSearchMsg = false;
    const searchTexts = this.config.AvoidingSearchText.split(',');
    let searchTextsExists = false;
    searchTexts.forEach(objonchange => {
      if (!searchTextsExists) {
        if (objonchange === this.typedValue) {
          searchTextsExists = true;
        }
      }
    });
    if (searchTextsExists) {
      this.showGenericSearchMsg = true;
      return;
    }
    if (this.typedValue.trim().toString().length >= 3) {
      this.searchFieldChanged.next(this.typedValue.toString());
      if (!this.cdr['destroyed']) this.cdr.detectChanges();
    }
    else {
      this.drugCodeResponse = [];
      this.showNoResultFoundMsg = false;
    }
    if (!this.cdr['destroyed']) this.cdr.detectChanges();
  }

  public callGlobalSearchViews(typedValue: string, searchType: string, searchId: number): void {
    typedValue = typedValue.trim();
    typedValue = encodeURI(typedValue).replace('/', '%2F');
    if (typedValue.length >= 3) {
      this.landingService.getDrugCodes(this.accountDetails.account_id, typedValue, searchType, searchId).subscribe(res => {
        this.drugCodeResponse = Object.assign([], res.body);
        this.drugCodeResponse.sort(this.compare);
        this.drugCodeResponse.sort(this.sort);
        if (this.drugCodeResponse.length === 0) {
          this.showNoResultFoundMsg = true;
          this.drugCodeResponse = undefined;
        } else {
          this.showNoResultFoundMsg = false;
        }
        if (!this.cdr['destroyed']) this.cdr.detectChanges();
      }, (err: Response) => {
        this.logger.error(err['error'].message);
      });
    }
  }

  public compare(a: GlobalSearchViews, b: GlobalSearchViews): number {
    const bandA = a.reimb_code;
    const bandB = b.reimb_code;
    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  public resetGS(): void {
    this.showNoResultFoundMsg = false;
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.checkSearchShow();
    },10);
  }

  public onSearchClick(): void {
    this.clickedInside = true;
    this.typedValue = this.typedValue.trim();
    if (this.typedValue !== '') {
      this.showGenericSearchMsg = false;
      const searchTexts = this.config.AvoidingSearchText.split(',');
      let searchTextsExists = false;
      searchTexts.forEach(objonSearchClick => {
        if (!searchTextsExists) {
          if (objonSearchClick === this.typedValue) {
            searchTextsExists = true;
          }
        }
      });
      if (searchTextsExists) {
        this.showGenericSearchMsg = true;
        return;
      }
      if (this.typedValue.toString().length >= 3) {
        this.redirectToFilterSearchPage();
      }
    }
    else {
      alert('Please enter a Drug, Diagnosis Code, Therapeutic Class to search for.');
    }
  }

  public sort(a: GlobalSearchViews, b: GlobalSearchViews): number {
    const columnA = a.sort_order;
    const columnB = b.sort_order;
    let comparison = 0;
    if (columnA > columnB) {
      comparison = 1;
    } else if (columnA < columnB) {
      comparison = -1;
    }
    return comparison;
  }

  public setPAData(paData: GlobalNotesOutPut[]): void {
    this.allPharmaCodeResult = paData;
    this.sharedService.PropagatePANotesOutput(this.allPharmaCodeResult);
  }
  public setGAData(gaData: GlobalNotesOutPut[]): void {
    this.allCodeResult = gaData;
    this.sharedService.PropagateGANotesOutput(gaData);
  }

  private navigationObj(hcpcsCode: GlobalSearchViews, alert: boolean, gnId: number, paGnId: number): NavigationExtras {
    return {
      queryParams: {
        rcId: hcpcsCode.rc_id,
        searchType: hcpcsCode.search_type,
        reimbCode: hcpcsCode.reimb_code,
        searchTerm: hcpcsCode.search_term,
        searchTexts: hcpcsCode.reimb_code,
        isNoc: hcpcsCode.is_noc,
        ndc: hcpcsCode.ndc,
        alertRequired: alert,
        gn_id: gnId,
        paGnId: paGnId,
      },
    };
  }

  private navigationObjWithoutGaPa(hcpcsCode: GlobalSearchViews): NavigationExtras {
    return {
      queryParams: {
        rcId: hcpcsCode.rc_id,
        searchType: hcpcsCode.search_type,
        reimbCode: hcpcsCode.reimb_code,
        searchTerm: hcpcsCode.search_term,
        searchTexts: hcpcsCode.reimb_code,
        ndc: hcpcsCode.ndc,
      },
    };
  }

  public redirectToJcard(hcpcsCode: GlobalSearchViews): void {
    this.clickedInside = true;
    this.userRecentSearches.push(this.typedValue);
    localStorage.setItem('key', JSON.stringify(this.userRecentSearches));
    this.usersLastTypedValueSearch = this.userRecentSearches;

    const filterDataPA = this.allPharmaCodeResult.filter(obj => obj.reimb_code === hcpcsCode.reimb_code);
    const filterDataGA = this.allCodeResult.filter(obj => obj.reimb_code === hcpcsCode.reimb_code);

    if (filterDataPA && filterDataGA && filterDataPA.length > 0 && filterDataGA.length > 0) {
      
        if (hcpcsCode.is_noc !== 'Y') {
          let navigationExtras: NavigationExtras = this.navigationObj(hcpcsCode, false, filterDataGA[0].gn_id, filterDataPA[0].gn_id);
          this.router.navigate(['/layout/jcard/drugCard'], navigationExtras);
        }
        if (hcpcsCode.is_noc === 'Y') {

          let navigationExtras: NavigationExtras = this.navigationObj(hcpcsCode, false, filterDataGA[0].gn_id, filterDataPA[0].gn_id);
          this.router.navigate(['/layout/noc'], navigationExtras);
        }
      
    }
    else {
      if (filterDataPA && filterDataPA.length > 0) {
      
          if (hcpcsCode.is_noc !== 'Y') {
            let navigationExtras: NavigationExtras = this.navigationObj(hcpcsCode, false, null, filterDataPA[0].gn_id);
            this.router.navigate(['/layout/jcard/drugCard'], navigationExtras);
          }
          if (hcpcsCode.is_noc === 'Y') {
            let navigationExtras: NavigationExtras = this.navigationObj(hcpcsCode, false, null, filterDataPA[0].gn_id);
            this.router.navigate(['/layout/noc'], navigationExtras);
          }
        
      }
      else if (filterDataGA && filterDataGA.length > 0) {
        
          if (hcpcsCode.is_noc !== 'Y') {
            let navigationExtras: NavigationExtras = this.navigationObj(hcpcsCode, false, filterDataGA[0].gn_id, null);
            this.router.navigate(['/layout/jcard/drugCard'], navigationExtras);
          }
          if (hcpcsCode.is_noc === 'Y') {
            let navigationExtras: NavigationExtras = this.navigationObj(hcpcsCode, false, filterDataGA[0].gn_id, null);
            this.router.navigate(['/layout/noc'], navigationExtras);
          }
        
      }
      else {
          if (hcpcsCode.is_noc !== 'Y') {
            let navigationExtras: NavigationExtras = this.navigationObjWithoutGaPa(hcpcsCode);
            this.router.navigate(['/layout/jcard/drugCard'], navigationExtras);
          }
          if (hcpcsCode.is_noc === 'Y') {
            let navigationExtras: NavigationExtras = this.navigationObjWithoutGaPa(hcpcsCode);
            this.router.navigate(['/layout/noc'], navigationExtras);
          }
        
      }
    }
  }

  public redirectToFilterSearchPage(): void {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        typedValue: this.typedValue,
        searchType: 'I',
        searchId: 0,
      },
    };
    this.router.navigate(['/layout/filter'], navigationExtras);
  }

  public redirectToFilterSearchPageBycallOnFixedOptionClick(searchType: string): void {
    this.clickedInside=true;
    let sId = 0;
    if (this.drugCodeResponse && this.drugCodeResponse.length > 0) {
      sId = this.drugCodeResponse[0].search_id;
    }
    let navigationExtras: NavigationExtras = {
      queryParams: {
        typedValue: this.typedValue,
        searchType: searchType,
        searchId: sId,
      },
    };
    this.router.navigate(['/layout/filter'], navigationExtras);
  }

  public showDanger(dangerTpl: string): void {
    this.toastService.show(dangerTpl, { classname: 'bg-danger text-light', delay: 3000 });
  }

  private checkSearchShow(): void {
    if (!this.clickedInside && this.typedValue !== null && this.typedValue.length > 0) {
      this.searchClick.emit('SHOW');
    } else {
      this.searchClick.emit('HIDE');
    }
    this.clickedInside = false;
  }

  public searchBoxFocus(): void {
		this.searchClick.emit('SHOW');
		this.showGlobalSearchDropdown = true;
  }

  @HostListener('click')
  public clickInside() : void{
    this.wasInside = true;
  }
  
  @HostListener('document:click')
  public clickout() : void {
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      if (!this.wasInside) {
				this.searchClick.emit('HIDE');
				this.showGlobalSearchDropdown = false;
			}
      this.wasInside = false;
    },10);
  }
}
