import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauth-privacy-policy',
  templateUrl: './unauth-privacy-policy.component.html',
  styleUrls: ['./unauth-privacy-policy.component.scss']
})
export class UnauthPrivacyPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
