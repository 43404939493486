import { Component, TemplateRef } from '@angular/core';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'app-toasts',
  templateUrl: './toast-container.component.html',
  host: {'[class.ngb-toasts]': 'true'},
})
export class ToastContainerComponent {

  constructor(public toastService: ToastService) {}
// tslint:disable:no-any
  public isTemplate(toast:any):any {
    return toast.textOrTpl instanceof TemplateRef;
  }
  // tslint:enable:no-any
}
