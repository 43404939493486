import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EnvironmentData } from 'src/app/shared/models/environment.data.model';
import { AccountOutput } from '../../shared/models/account-output.data.model';
import { CompareObject } from '../../shared/models/compare-object.data.model';
import { CommonService } from '../../shared/services/common.service';

@Component({
  selector: 'landing-layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit, OnDestroy {

  public showFullMenu: boolean = true;
  public showGlobalSearch: boolean = true;
  public showStandardView: boolean;
  public accountDetails: AccountOutput;
  public config: EnvironmentData;
  public compareArrayList: CompareObject[] = [];
  private subscription: Subscription;
  
  public screenWidth: number;
  public openPopover: string;

  constructor(
    private _router: Router,
    private cdr: ChangeDetectorRef,
    private commonService: CommonService,
  ) {
    this.subscription = this.commonService.SubscribeCompareList().subscribe(arrayList => {
      this.compareArrayList = arrayList;
    });
  }

  public ngOnInit(): void {
    this.config = sessionStorage.getItem('config') ? JSON.parse(sessionStorage.getItem('config')) : null;
    this.accountDetails = sessionStorage.getItem('accountDetails') ? JSON.parse(sessionStorage.getItem('accountDetails')) : null;
    this.showStandardView = this.accountDetails.hp_view === 'SV';
    this.checkRoute();
    this._router.events.subscribe((val) => {
      this.checkRoute();
    });
    this.compareArrayList = localStorage.getItem('compareList') ? JSON.parse(localStorage.getItem('compareList')) : [];
    if (this.screenWidth < 1199) {
      this.openPopover = 'click';
    }
    else {
      this.openPopover = 'mouseenter:mouseleave, click';
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public checkRoute(): void {
    if (window.location.hash !== '#/layout') {
      this.showFullMenu = false;
      this.showGlobalSearch = false;
    } else {
      this.showFullMenu = true;
      this.showGlobalSearch = true;
    }
    if (!this.cdr['destroyed']) this.cdr.detectChanges();
  }

  public removeAll(): void {
    this.compareArrayList = [];
    localStorage.setItem('compareList', JSON.stringify(this.compareArrayList));
    this.commonService.PropagateEmptyCompareList(true);
    this.commonService.PropagateCompareList(this.compareArrayList);
  }

  public removeItem(i: number): void {
    let count: number = 0;
    if (i > -1) {
      this.compareArrayList[i] = { isRemoved: true };
      for (let i = 0; i < this.compareArrayList.length; i++) {
        if (this.compareArrayList[i].isRemoved) {
          count++;
        }
      }
      if (count === this.compareArrayList.length) {
        this.compareArrayList = [];
        this.commonService.PropagateEmptyCompareList(true);
      }
      localStorage.setItem('compareList', JSON.stringify(this.compareArrayList));
      this.commonService.PropagateCompareList(this.compareArrayList);
    }
  }
}
