import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as LogRocket from 'logrocket';
import { AccountOutput, EnvironmentData } from 'src/app/shared/models';
import { AuthorizationService } from 'src/app/shared/services/authorization.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { Logger } from 'src/app/shared/services/logger.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.scss']
})
export class LoginErrorComponent implements OnInit {
  public username: string = '';
  public password: string = '';
  public error: boolean = false;
  public errMsg: string = '';
  public success: boolean = false;
  public successMsg: string = '';
  public delMsg: boolean = false;
  public isTerminated: boolean = false;
  public isValid: boolean = false;
  public isLoginWait: boolean = false;
  public message: string = '';
  public config: EnvironmentData;
  public encKey: string = '';
  public selectedAccountId: string = '';
  public dateTimePassedByAma: string = new Date().toString();
  public showUserDropdown: boolean = false;
  public display: string = 'none';
  public redirectURL: string = '';
  public fieldTextType: boolean;
  @ViewChild('dangerTpl', { static: false }) public dangerTpl: string;

  LoginForm = this.fb.group({
    username: [null, Validators.required],
    password: [null, Validators.required]
  })

  constructor(
    private router: Router,
    private toastService: ToastService,
    private authService: AuthorizationService,
    private cdr: ChangeDetectorRef,
    private globalwindowService: GlobalService,
    private logger: Logger,
    private loginService: LoginService,
    private fb: FormBuilder,
  ) { }

  public ngOnInit(): void {
    this.config = JSON.parse(sessionStorage.getItem('config'));
    sessionStorage.setItem('isPageLoadedFirstTime', 'true');
    this.errMsg = sessionStorage.getItem('errMsg');
    this.message = sessionStorage.getItem('delMsg');
    if (this.errMsg) {
      this.error = true;
      sessionStorage.removeItem('errMsg');
    }

    if (this.message) {
      this.delMsg = true;
      sessionStorage.removeItem('delMsg');
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  submitData() {
    this.isLoginWait = true
    this.error = false;
    this.delMsg = false;
    this.isValid = true;
    this.success = false;
    this.username = this.LoginForm.controls.username.value;
    if (this.username === null || this.username === undefined || this.username.length === 0) {
      this.isLoginWait = false
      this.error = true;
      this.errMsg = 'Username/Email is required.';
      this.isValid = false;
    }

    if (this.isValid) {
      this.loginService.username_lookup(this.username).subscribe(res => {
        if (res.status === 200) {
          this.redirectURL = res.body[0]['sso_redirect_url'];
          this.username = res.body[0]['email'];
          if (this.redirectURL) {
            window.location.replace(this.redirectURL);
          } else {
            this.loginSubmit();
          }
        } else if (res.status === 204) {
          this.isLoginWait = false
          this.error = true;
          this.errMsg = 'Invalid username/email';
        }
      }, () => {
        this.isLoginWait = false
        this.error = true;
        this.errMsg = 'Unknown error';
      });
    }
  }

  loginSubmit() {
    this.isValid = true;
    this.username = this.LoginForm.controls.username.value;
    this.password = this.LoginForm.controls.password.value;

    if (this.redirectURL) {
      window.location.replace(this.redirectURL);
    }

    if (this.password === null || this.password === undefined || this.password.length === 0) {
      this.isLoginWait = false
      this.error = true;
      this.errMsg = 'Password is required.';
      this.isValid = false;
    }

    let jsonData =
    {
      'username': this.username,
      'password': this.password
    };
    if (this.isValid) {
      this.success = true;
      this.successMsg = 'Authenticating...'
      this.loginService.getAmaLoginDetails(jsonData).subscribe(res => {
        if (res && res[0]['sso_redirect_url']) {
          window.location.replace(res[0]['sso_redirect_url']);
        } else if (res && res[0]['session_id']) {
          if (res[0]['termination_date']) this.checkValidDate(res[0]['termination_date']);
          if (this.isTerminated) {
            this.isLoginWait = false
            this.success = false;
            this.successMsg = ''
            this.delMsg = false;
            this.error = true;
            this.errMsg = 'Your account has been terminated';
          } else {
            sessionStorage.setItem('uid', res[0]['session_id']);
            this.selectedAccountId = res[0]['account_id'];
            if (res[0]['website_product_name'] === 'RC2') {
              this.getAccountDetails(this.selectedAccountId);
            } else {
              this.loginService.rc_navigateApplication(this.password, this.username, res[0]['aa_id'], res);
            }
          }
        } else if (res && res['message']) {
          this.isLoginWait = false
          this.success = false;
          this.successMsg = ''
          this.delMsg = true;
          this.message = res['message'];
        } else {
          this.isLoginWait = false
          this.success = false;
          this.successMsg = ''
          this.error = true;
          this.errMsg = 'Username / Password is incorrect.';
        }

      }, err => {
        this.isLoginWait = false
        if (err.status === 401 || err.status === '401') {
          this.delMsg = true;
          this.message = err['error'].message;
        } else {
          this.error = true;
          this.errMsg = err['error'].message;
        }
      });
    }
  }

  checkValidDate(dateValue: string = null) {
    const dateFilter = new Date(dateValue);
    const currentDate = new Date();
    this.isTerminated = currentDate.getTime() > dateFilter.getTime() ? true : false
  }

  deleteExistingSession() {
    this.success = false;
    this.error = false;
    const session_id = this.message.split(':')[1];
    sessionStorage.setItem('uid', session_id);
    this.authService.deleteSession(session_id).subscribe(() => {
      this.delMsg = false;
      this.username = '';
      this.password = '';
      sessionStorage.removeItem('uid');
    }, () => {
      this.error = true;
      this.errMsg = 'Error in deleting session'
    });
  }

  public getAccountDetails(accountId: string): void {
    this.authService.getAccountDetails(accountId).subscribe(res => {
      var loggedInDateTime = new Date(this.dateTimePassedByAma);
      var currentDateTime = new Date(res['current_date_time']);
      loggedInDateTime.setMinutes(loggedInDateTime.getMinutes() + 10);
      if (loggedInDateTime < currentDateTime) {
        this.showDanger('Login token time has expired/Invalid token.');
        return;
      }
      sessionStorage['isValidUser'] = true;
      sessionStorage.setItem('accountDetails', JSON.stringify(res[0]));
      sessionStorage.setItem('showFirstTimeWelcome', 'true');
      this.insertGainsight(res[0]);
      if (!this.cdr['destroyed']) this.cdr.detectChanges();
      this.logRocket(res[0]);
      setTimeout(() => {
        this.router.navigate(['/layout']);
      }, 1000);

    }, (err: Response) => {
      this.showDanger('error in service:' + err['error'].message);

    });
  }

  public logRocket(accountDetails: AccountOutput): void {
    if (!this.config) {
      this.globalwindowService.getConfig().subscribe(res => {
        sessionStorage.setItem('config', JSON.stringify(res));
        this.identifyLogRocket(accountDetails, res['CurrentEnvironment']);
      }, (err: Response) => {
        this.logger.error(err['error'].message);
        this.showDanger('error in service:' + err['error'].message);
      });
    } else {
      this.identifyLogRocket(accountDetails, this.config['CurrentEnvironment']);
    }
  }

  private identifyLogRocket(accountDetails: AccountOutput, env: string): void {
    if (env === 'UAT') {
      if (accountDetails.username !== 'AutoFSUser1') {
        LogRocket.init('rj-health/rc3');
        LogRocket.identify('rj-health/rc3', {
          name: accountDetails.username,
          email: accountDetails.email,
        });
      }
    }
  }
  public checkUsername() {
    this.isValid = true;
    this.username = this.LoginForm.controls.username.value;
    if (this.username === null || this.username === undefined || this.username.length === 0) {
      this.error = true;
      this.errMsg = 'Username is required.';
      this.isValid = false;
      this.redirectURL = '';
    }

    if (this.isValid) {
      this.loginService.username_lookup(this.username).subscribe(res => {
        if (res.status === 200) {
          this.redirectURL = res.body[0]['sso_redirect_url'];
        } else if (res.status === 204) {
          this.error = true;
          this.errMsg = 'Invalid username/email';
        }
      }, () => {
        this.error = true;
        this.errMsg = 'Unknown error';
      });
    }
  }
  public showDanger(dangerTpl: string): void {
    this.toastService.show(dangerTpl, { classname: 'bg-danger text-light', delay: 15000 });
  }

  public openModal(): void {
    this.display = 'block';
  }

  public onCloseHandled(): void {
    this.display = 'none';
    sessionStorage.clear();
    sessionStorage.removeItem('encKey');
    location.replace(this.config.RC3NewUrl);
  }

  private insertGainsight(accountDetails: AccountOutput) {
    const head = document.getElementsByTagName('head')[0];
    let appId = sessionStorage.getItem('gs')
    const script = document.createElement('script');
    script.innerHTML = `<!-- Gainsight PX Tag-->
                         
                          (function (n, t, a, e, co) {
                              var i = "aptrinsic"; n[i] = n[i] || function () {
                                  (n[i].q = n[i].q || []).push(arguments)
                              }, n[i].p = e; n[i].c = co;
                              var r = t.createElement("script"); r.async = !0, r.src = a + "?a=" + e;
                              var c = t.getElementsByTagName("script")[0]; c.parentNode.insertBefore(r, c)
                          })(window, document, "https://web-sdk.aptrinsic.com/api/aptrinsic.js", "`+appId+`", {"fullDomainCookie":true});
  
                          aptrinsic('set', 'globalContext', { "Product":"ReimbursementCodes" });
                          //passing user and account objects:
                          aptrinsic("identify",
                              {
                                  //User Fields
                                  "id": "`+accountDetails.username+`" , // Required for logged in app users
                                  "email": "`+accountDetails.email+`" ,
                                  "firstName": "`+accountDetails.contact_first+`",
                                  "lastName": "`+accountDetails.contact_last+`"
                              },
                              {
                                  //Account Fields
                                  "id":  "`+accountDetails.org_id+`" , //Required
                                  "name": "`+accountDetails.org_name+`"
                              });
                      <!-- Gainsight PX Tag-->
                      
                      `;
      head.insertBefore(script, head.lastChild);
    }
  
  }
