import {
    Component, ElementRef, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-control',
  templateUrl: './date-control.component.html',
})
export class DateControlComponent implements OnInit {
  public datePickerValue: NgbDateStruct;
  @Input()  public fromAdminTools: boolean ;
  @Input() public disabled: boolean;
  public minDate: {year: number, month: number, day: number} = {year: 2000, month: 1, day: 1};
  public maxDate: {year: number, month: number, day: number} = {year: 2048, month: 12, day: 31};
  @Output() public emitSelectedDateValue: EventEmitter<NgbDateStruct> = new EventEmitter<NgbDateStruct>();

  constructor(
    private calendar: NgbCalendar,
    private inputEmpty: ElementRef,
  ) { }

  public ngOnInit(): void {
    if(this.fromAdminTools){
      return;
    } else{
      this.setDateToCurrent();
    }
    
  }

  public emitModelData(): void {
    this.emitSelectedDateValue.emit(this.datePickerValue);
  }

  public setDateToCurrent(): void {
    this.datePickerValue = this.calendar.getToday();
  }

  public makeDateEmpty():void {
    this.datePickerValue = {year:0,month:0,day:0};
  }

  public checkEmptyInputField():void{
    let myTag = this.inputEmpty.nativeElement.querySelectorAll('input');
    myTag.forEach(element => {
      element.classList.add('ng-dirty');
    });
  }

}
