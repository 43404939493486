import { Component, OnInit } from '@angular/core';
import { EnvironmentData } from 'src/app/shared/models/environment.data.model';

@Component({
  selector: 'layout-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  public config: EnvironmentData;
  public applicationVersion: string;
  public curentyear: number;
  public previousyear: number;
  constructor() { const i = 0; }
  public ngOnInit(): void {
    this.curentyear = new Date().getFullYear();
    this.previousyear = new Date().getFullYear() - 1;
    this.config = JSON.parse(sessionStorage.getItem('config'));
    this.applicationVersion = this.config.ApplicationVersionNo;
  }
}
