import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { EnvironmentData } from "../models/environment.data.model";

@Injectable({
    providedIn: 'root',
})

export class RegistrationService {
    public headers: HttpHeaders = null;
    public sessionId: string = '';
    public config: EnvironmentData = JSON.parse(sessionStorage.getItem('config'));
    constructor(
        private _http: HttpClient,
        private router: Router,
        public snackbar: MatSnackBar
    ) { }

    private setHeaders(): HttpHeaders {
        this.config = JSON.parse(sessionStorage.getItem('config'));
        let apiKey = sessionStorage.getItem('ak')
        return this.headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('x-api-key', apiKey)
            .set('Authorization', sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') : this.config.Authorization);
      }

    errorMessage(message: string) {
        this.snackbar.open(message, '', {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            panelClass: ['error'],
            duration: 5000,
        });
    }

    public addAccount(jsonData: object): Observable<object> {
        let headers = this.setHeaders();
        return this._http.post(this.config.ServiceRootUrl + "/account/addAccount", jsonData, { headers: headers });
    }

    public domainCheck(email: string) {
        let headers = this.setHeaders();
        return this._http.get(this.config.ServiceRootUrl + "/domaincheck/" + email, { headers: headers });
    }

    public companyPass(code: string) {
        let headers = this.setHeaders();
        return this._http.get(this.config.ServiceRootUrl + "/companypass/" + code, { headers: headers, observe: 'response' });
    }

    public npi_lookup(npi: string) {
        let headers = this.setHeaders();
        return this._http.get(this.config.ServiceRootUrl + '/lookupnpi/' + npi, { headers, observe: 'response' })
    }

    public username_lookup(username: string) {
        let headers = this.setHeaders();
        return this._http.post(this.config.ServiceRootUrl + '/amalogin/lookup', { 'username': username }, { headers, observe: 'response' })
    }

    //Validation Services
    public validateOrg(jsonData: object): Observable<object> {
        let headers = this.setHeaders();
        return this._http.post(this.config.ServiceUrl + '/email/validateOrg', jsonData, { headers });
    }

    public validateEmail(jsonData: object): Observable<object> {
        let headers = this.setHeaders();
        return this._http.post(this.config.ServiceUrl + '/email/validateEmail', jsonData, { headers });
    }

    public validateUsername(jsonData: object): Observable<object> {
        let headers = this.setHeaders();
        return this._http.post(this.config.ServiceUrl + '/email/validateUsername', jsonData, { headers });
    }

    public validatePassword(jsonData: object): Observable<object> {
        let headers = this.setHeaders();
        return this._http.post(this.config.ServiceUrl + '/email/validatePassword', jsonData, { headers });
    }

    public varificationEmail(jsonData: object): Observable<object> {
        let headers = this.setHeaders();
        return this._http.post(this.config.ServiceUrl + '/email/sendVerification', jsonData, { headers });
    }

    public sendFeedback(jsonData: object): Observable<object> {
        let headers = this.setHeaders();
        return this._http.post(this.config.ServiceUrl + '/email/sendFeedback', jsonData, { headers });
    }
    
}